import { clsMerge } from "@artifactlabs/shared-react-utils";
import { FunctionComponent } from "react";
import { PhoneInput, PhoneInputProps } from "react-international-phone";
import "react-international-phone/style.css";

import { poppins } from "@/utils/font";

interface CustomPhoneInputProps extends PhoneInputProps {}

const CustomPhoneInput: FunctionComponent<CustomPhoneInputProps> = ({ disabled, ...rest }) => {
  //
  return (
    <PhoneInput
      className={clsMerge(
        "h-[42px] ring-1 ring-[#DCDCDC] focus-within:ring-[1px] focus-within:ring-[#2684FF]",
        disabled && "ring-0",
        poppins.className,
      )}
      countrySelectorStyleProps={{
        buttonStyle: {
          border: 0,
          height: "100%",
          width: 60,
          outlineWidth: 0,
          fontSize: "inherit",
        },
        buttonClassName: "focus-within:ring-[1px] focus-within:ring-[#2684FF]",
        dropdownStyleProps: {
          style: {
            width: 456,
            outline: 0,
            marginTop: 2,
            maxHeight: "15rem",
            borderRadius: "0.375rem",
            fontSize: "inherit",
          },
          listItemStyle: {
            paddingRight: "2.25rem",
            paddingLeft: "0.75rem",
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
          },
        },
      }}
      dialCodePreviewStyleProps={{
        style: {
          border: 0,
          width: 60,
          fontSize: "inherit",
          fontFamily: "inherit",
          fontWeight: "inherit",
          color: "#87888C",
          WebkitAppearance: "none",
          MozAppearance: "none",
          backgroundColor: disabled ? "#DCDCDC" : "white",
        },
      }}
      disabled={disabled}
      disableDialCodeAndPrefix={true}
      inputClassName="focus:ring-transparent"
      inputStyle={{
        border: 0,
        width: "100%",
        height: "100%",
        outline: "none",
        fontSize: "inherit",
        fontFamily: "inherit",
      }}
      showDisabledDialCodeAndPrefix={true}
      style={{
        borderRadius: 4,
        height: 42,
      }}
      {...rest}
    />
  );
};

export default CustomPhoneInput;
