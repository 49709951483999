import { clsMerge } from "@artifactlabs/shared-react-utils";
import { faChevronLeft, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Combobox } from "@headlessui/react";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useParams, useSearchParams, useRouter } from "next/navigation";
import { useCallback, useMemo, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { useDebounce } from "usehooks-ts";

import { Facet } from "@/gql/graphql";
import { useCloseModal } from "@/hooks/useModal";
import { useWindowResize } from "@/hooks/useWindowResize";
import tenantAtom from "@/recoil/tenant";
import { poppins } from "@/utils/font";
import { mapFaceKeyToLabel } from "@/utils/helpers/filters";
import { routes } from "@/utils/routes";

interface SearchbarFilterModalViewProps {
  facet: Facet;
  paramKey: string;
}

export const SearchbarFilterModalView = ({ paramKey, facet }: SearchbarFilterModalViewProps) => {
  const [query, setQuery] = useState("");
  const debounceQuery = useDebounce(query, 300);
  const inputRef = useRef<HTMLInputElement>(null);

  const closeModal = useCloseModal();
  const isMobile = useWindowResize();

  const router = useRouter();
  const searchParams = useSearchParams();
  const params = useParams();
  const [, mediaType] = params?.slug as string[];

  const tenant = useRecoilValue(tenantAtom);
  const facetLabel = mapFaceKeyToLabel(paramKey, tenant?.id ?? "")?.label;

  const selected = useMemo(() => {
    const params = new URLSearchParams(searchParams || "");
    if (params.has(paramKey)) {
      let found = params.get(paramKey);

      if (found) {
        let paramsValues = found.split("||");

        return paramsValues;
      }
    }
    return [];
  }, [paramKey, searchParams]);

  const handleSelectValue = useCallback(
    (each: any) => {
      const params = new URLSearchParams(searchParams || "");
      if (params.has(paramKey)) {
        let found = params.get(paramKey);
        if (found) {
          let paramsValues = found.split("||");

          if (found.includes(each._id)) {
            let newFilterParamsValues = paramsValues.filter((param: string) => {
              return param !== each._id;
            });
            if (newFilterParamsValues.length > 0) {
              params.set(paramKey, newFilterParamsValues.join("||"));
            } else {
              params.delete(paramKey);
            }
          } else {
            params.set(paramKey, [found, each._id].join("||"));
          }
        }
      } else {
        params.set(paramKey, each._id);
      }
      const url = routes.search(/*page*/ 1, mediaType, params);
      router.push(url, { scroll: false });
      setQuery("");
    },
    [mediaType, paramKey, router, searchParams, setQuery],
  );

  const valuesWithHeaders = useMemo(() => {
    const sorted = [...(facet?.buckets ?? [])].sort((a, b) =>
      (a?._id as string).localeCompare(b?._id as string),
    );
    return sorted.reduce(
      (acc, each) => {
        const firstLetter = each?._id?.[0]?.toUpperCase();

        if (!acc[acc.length - 1] || acc[acc.length - 1]?.header !== firstLetter) {
          acc.push({ header: firstLetter as string, values: [each] });
        } else {
          acc[acc.length - 1]?.values?.push(each);
        }

        return acc;
      },
      [] as { header: string; values: typeof facet.buckets }[],
    );
  }, [facet]);

  const potentiallyFilteredResults = useMemo(() => {
    return debounceQuery?.[1]
      ? valuesWithHeaders
          .filter(
            ({ header, values }) =>
              header === debounceQuery.toUpperCase() ||
              values?.some(each => each?._id?.match(new RegExp(debounceQuery, "i"))),
          )
          .map(({ header, values }) => ({
            header,
            values: values?.filter(each => each?._id?.match(new RegExp(debounceQuery, "i"))),
          }))
      : valuesWithHeaders;
  }, [debounceQuery, valuesWithHeaders]);

  return (
    <div
      className={clsMerge(
        "overflow-y-auto bg-white",
        "h-[100vh] w-[100vw] px-[15px] py-[30px]",
        "md:h-[80vh] md:w-[560px] md:rounded-xl",
      )}
    >
      <div
        className={clsMerge(
          "flex flex-col items-center justify-center antialiased",
          "h-fit px-[15px]",
          // "md:px-[30px] md:py-[30px]",
        )}
      >
        <div className="flex w-full items-center gap-4 md:justify-between">
          {isMobile && (
            <FontAwesomeIcon
              className="fa-xl max-h-[24px] max-w-[24px]"
              icon={faChevronLeft}
              onClick={closeModal}
            />
          )}

          <div className={clsMerge("text-xl font-normal", poppins.className)}>{facetLabel}</div>

          {!isMobile && (
            <FontAwesomeIcon
              className="fa-xl max-h-[24px] max-w-[24px]"
              icon={faClose}
              onClick={closeModal}
            />
          )}
        </div>

        <Combobox value={selected} multiple>
          <div
            className="mt-[40px] flex w-full border-[1px] border-[#343434] p-1"
            onClick={() => {
              if (inputRef.current) inputRef.current.focus();
            }}
          >
            <div className="flex">
              <div className="mx-1 flex items-center justify-center">
                <MagnifyingGlassIcon className="h-5 w-5 text-neutral-700" />
              </div>

              <div className="flex flex-wrap gap-2 overflow-y-auto">
                {selected.map(artist => (
                  <div
                    className={clsMerge(
                      "inline-block rounded border-[1px] border-[#87888C] bg-[#E6E6E6] p-1",
                    )}
                    key={artist}
                    onClick={() => handleSelectValue({ _id: artist })}
                  >
                    {artist}
                    <XMarkIcon className="inline h-5 w-5 text-center text-neutral-700" />
                  </div>
                ))}
                <Combobox.Input
                  className={"w-fit min-w-[60px] border-none outline-none focus:ring-0"}
                  ref={inputRef}
                  value={query}
                  onChange={e => setQuery(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="w-[100%]">
            <Combobox.Options className={"min-h-[100vh] md:min-h-fit"} static={true}>
              {potentiallyFilteredResults.map(({ header, values }) => {
                return (
                  <div className="flex w-full flex-col" key={header}>
                    <p className="mt-4 text-sm text-[#87888C]">{header}</p>
                    <div className="border-b-[1px] border-b-[#EDEDED]" />

                    {values?.map(each => {
                      return (
                        <div
                          className="mt-2 h-[36px] cursor-pointer select-none text-sm text-[#343434]"
                          key={each!._id}
                          onClick={() => {
                            handleSelectValue(each);
                            setQuery("");
                          }}
                        >
                          {each!._id}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </Combobox.Options>
          </div>
        </Combobox>
      </div>
    </div>
  );
};

export default SearchbarFilterModalView;
