import { ReadonlyURLSearchParams } from "next/navigation";

import { getLocalizationProvider } from "@/utils/tenant";

export type ParsedSearchResultRouterQuery = {
  keyword: string;
  sort: string;
  slug: Array<string>;
  relevantDatesFacet?: string;
  creatorNameFacet?: string;
  relevantGeosFacet?: string;
  materialsAndTechnologyFacet?: string;
  orientationFacet?: string;
  copyrightOrRestrictionsFacet?: string;
};

export type typeSupportedFacet =
  | "relevantDatesFacet"
  | "creatorNameFacet"
  | "relevantGeosFacet"
  | "materialsAndTechnologyFacet"
  | "orientationFacet"
  | "copyrightOrRestrictionsFacet"
  | "assetTypeFacet"
  | "seriesNameFacet"
  | "exhibitionLoanHistoryFacet"
  | "creationDateFacet";

export const supportedFacet: Array<typeSupportedFacet> = [
  "orientationFacet",
  "copyrightOrRestrictionsFacet",
  "relevantDatesFacet",
  "creatorNameFacet",
  "relevantGeosFacet",
  "materialsAndTechnologyFacet",
  "assetTypeFacet",
  "seriesNameFacet",
  "exhibitionLoanHistoryFacet",
  "creationDateFacet",
];

export const facetKeysToLabel = (facetKey: typeSupportedFacet) => {
  if (facetKey === "copyrightOrRestrictionsFacet") {
    return {
      copyright_expired: "Copyright expired",
      copyright_limited: "Copyright limited",
      full_copyright_granted: "Full copyright granted",
      no_contact: "No contact",
      no_copyright: "No copyright",
    };
  }

  return {};
};

export const mapFaceKeyToLabel = (facetKey: string, tenantId: string) => {
  //
  if (facetKey === "relevantDatesFacet") {
    return {
      key: "relevantDatesFacet",
      label: getLocalizationProvider(tenantId).facet.relevantDatesFacet,
      path: "metadata.relevantDates",
    };
  } else if (facetKey === "creatorNameFacet") {
    return {
      key: "creatorNameFacet",
      label: getLocalizationProvider(tenantId).facet.creatorNameFacet,
      path: "metadata.creatorName",
    };
  } else if (facetKey == "relevantGeosFacet") {
    return {
      key: "relevantGeosFacet",
      label: getLocalizationProvider(tenantId).facet.relevantGeosFacet,
      path: "metadata.relevantGeos",
    };
  } else if (facetKey == "materialsAndTechnologyFacet") {
    return {
      key: "materialsAndTechnologyFacet",
      label: getLocalizationProvider(tenantId).facet.materialsAndTechnologyFacet,
      path: "metadata.materialsAndTechnology",
    };
  } else if (facetKey === "orientationFacet") {
    return {
      key: "orientationFacet",
      label: getLocalizationProvider(tenantId).facet.orientationFacet,
      path: "metadata.orientation",
    };
  } else if (facetKey === "copyrightOrRestrictionsFacet") {
    return {
      key: "copyrightOrRestrictionsFacet",
      label: getLocalizationProvider(tenantId).facet.copyrightOrRestrictionsFacet,
      path: "metadata.copyrightOrRestrictions",
    };
  } else if (facetKey === "assetTypeFacet") {
    return {
      key: "assetTypeFacet",
      label: getLocalizationProvider(tenantId).facet.assetTypeFacet,
      path: "metadata.assetType",
    };
  } else if (facetKey === "seriesNameFacet") {
    return {
      key: "seriesNameFacet",
      label: getLocalizationProvider(tenantId).facet.seriesNameFacet,
      path: "metadata.seriesName",
    };
  } else if (facetKey === "exhibitionLoanHistoryFacet") {
    return {
      key: "exhibitionLoanHistoryFacet",
      label: getLocalizationProvider(tenantId).facet.exhibitionLoanHistoryFacet,
      path: "metadata.exhibitionLoanHistory",
    };
  } else if (facetKey === "creationDateFacet") {
    return {
      key: "creationDateFacet",
      label: getLocalizationProvider(tenantId).facet.creationDateFacet,
      path: "metadata.creationDate",
    };
  }
};

export const getExactFilters = (query: any, tenantId: string) => {
  const filters: any = [];
  const andFilter: any = [];
  supportedFacet.forEach(facetKey => {
    const facetValue = query[facetKey];
    if (facetValue) {
      const facet = mapFaceKeyToLabel(facetKey, tenantId);
      if (facet) {
        const filterValuesArr = facetValue?.split("||") || [];
        if (filterValuesArr.length > 1) {
          const filterGroup: any = [];
          filterValuesArr.forEach((orQuery: string) => {
            filterGroup.push({
              path: facet.path,
              query: orQuery,
            });
          });
          filters.push(filterGroup);
        } else {
          andFilter.push({
            path: facet.path,
            query: facetValue,
          });
        }
      }
    }
  });
  const result: any = {};
  if (filters.length > 0) {
    result.filters = filters;
  }
  if (andFilter.length > 0) {
    result.andFilter = andFilter;
  }
  return result;
};

export const getAppliedFilters = (searchParams: ReadonlyURLSearchParams, tenantId: string) => {
  const exactFilters: any = [];
  supportedFacet.forEach(facetKey => {
    const paramsFacetValue = searchParams?.get(facetKey);
    if (paramsFacetValue) {
      const facetValueArr = paramsFacetValue.split("||");
      if (facetValueArr.length > 1) {
        for (const each of facetValueArr) {
          const facet = mapFaceKeyToLabel(facetKey, tenantId);
          if (facet) {
            const filter = {
              key: facet.key,
              path: facet.path,
              query: each,
            };
            exactFilters.push(filter);
          }
        }
      } else {
        let facetValue = facetValueArr[0];
        const facet = mapFaceKeyToLabel(facetKey, tenantId);
        if (facet) {
          const filter = {
            key: facet.key,
            path: facet.path,
            query: facetValue,
          };
          exactFilters.push(filter);
        }
      }
    }
  });
  return exactFilters.length > 0 ? { filter: exactFilters } : null;
};
